<script>
import Layout from "../../layouts/main";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import axios from 'axios';
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    Layout,
    EasyDataTable,
    // ReportStatus
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      searchValueTable: "",
      addStaff: false,
      selectFilterSort: null,
      app_URL: process.env.VUE_APP_URL,
      submitted: false,
      staff: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      headers: [
        { text: "Name", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actionBtn" },
      ],

      items: [],
      arrItem: [],
      staffUpdate: false,
      editedIndex: -1,

    };
  },
  activated() {
    this.getStaff();
  },
  created() {
    this.getStaff();
  },
  validations: {
    staff: {
      firstName: {
        required: helpers.withMessage("first Name is required", required),
      },
      lastName: {
        required: helpers.withMessage("Last Name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Valid email is required", email)
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    }
  },
  methods: {
    resetAdd() {
      this.staff = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
        this.staffUpdate = false
    },
    submitStaff() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      } else {
        axios.post(this.app_URL + "api/addStaff", this.staff).then(() => {
          this.addStaff = false;
          this.getStaff();
          this.$store.state.isAccepted = true
          this.$store.state.notificationData = 'Staff Created Successfully'
          setTimeout(() => {
            this.$store.state.isAccepted = false
            this.$store.state.notificationData = null
          }, 2500)
        }).catch((error) => {
          this.$store.state.isDeclined = true
          this.$store.state.notificationData = error.response.data.errors.email[0]
          setTimeout(() => {
            this.$store.state.isDeclined = false
            this.$store.state.notificationData = null
          }, 2500)
        });
      }
    },
    getStaff() {
      axios.post(this.app_URL + "api/getStaff").then(res => {
        this.arrItem = res.data;
      })
    },
    editStaff(id) {
      axios.post(this.app_URL + "api/editStaff", { id: id }).then((res) => {
        this.staff = res.data.staff;
        this.staff.password = res.data.password
        this.addStaff = true;
        this.staffUpdate = true;
      });
    },
    updateStaff() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      } else {
        axios.post(this.app_URL + "api/updateStaff", this.staff).then(() => {
          this.addStaff = false;
          this.getStaff();
          this.$store.state.isAccepted = true
          this.$store.state.notificationData = 'Staff Updated Successfully'
          setTimeout(() => {
            this.$store.state.isAccepted = false
            this.$store.state.notificationData = null
          }, 2500)
        }).catch((error) => {
          this.$store.state.isDeclined = true
          this.$store.state.notificationData = error.response.data.errors.email[0]
          setTimeout(() => {
            this.$store.state.isDeclined = false
            this.$store.state.notificationData = null
          }, 2500)
        });
      }
    },
    deleteStaff(id) {
      if (confirm("Are you sure you want to delete")) {
        axios.post(this.app_URL + "api/deleteStaff", { id: id }).then(() => {
          this.$store.state.isAccepted = true
          this.$store.state.notificationData = 'Staff Deleted Successfully'
          setTimeout(() => {
            this.$store.state.isAccepted = false
            this.$store.state.notificationData = null
          }, 2500)
          this.getStaff();
        });
      }
    },
    updateStatus(id) {
      let item = this.arrItem.find(item => item.id === id);
      if (item.status == 1) {
        item.status = 0;
      } else {
        item.status = 1;
      }
      axios.post(this.app_URL + "api/updateStatus", { id: id }).then(() => {
        this.$store.state.isAccepted = true
        this.$store.state.notificationData = 'Status Updated Successfully'
        setTimeout(() => {
          this.$store.state.isAccepted = false
          this.$store.state.notificationData = null
        }, 2500)
      });
    }
  },

};
</script>

<template>
  <Layout>
    <main class="py-4">
      <div class="row mb-3">
        <div class="col">
          <h2 class="font-size-22 text-theme-dark">All Staff List</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row mb-3 align-items-center">
            <div class="col-lg-6 mt-3 mt-md-0">
              <input type="text" v-model="searchValueTable" placeholder="Search..."
                class="customInputForm form-control" />
            </div>
            <div class="col-lg-6 text-end">
              <a href="javascript:void(0);" class="btn btn-theme-dark" @click="addStaff = true, resetAdd()">+ Add
                Staff</a>
              <b-modal title="Add Staff" v-model="addStaff" hide-footer centered>
                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-block mb-3">
                        <label class="font-size-14">First Name </label>
                        <input v-model="staff.firstName" type="text" class="form-control simpleInputStyle"
                          placeholder="First Name" :class="{ 'is-invalid': submitted && v$.staff.firstName.$error, }" />
                        <div class="invalid-feedback" v-for="(error, key) in v$.staff.firstName.$errors" :key="key">
                          <span v-if="error.$message">{{ error.$message }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Last Name </label>
                        <input v-model="staff.lastName" type="text" class="form-control simpleInputStyle"
                          placeholder="Last Name" :class="{ 'is-invalid': submitted && v$.staff.lastName.$error, }" />
                        <div class="invalid-feedback" v-for="(error, key) in v$.staff.lastName.$errors" :key="key">
                          <span v-if="error.$message">{{ error.$message }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Email </label>
                        <input v-model="staff.email" type="email" class="form-control simpleInputStyle"
                          placeholder="Email" :class="{ 'is-invalid': submitted && v$.staff.email.$error, }" />
                        <div class="invalid-feedback" v-for="(error, key) in v$.staff.email.$errors" :key="key">
                          <span v-if="error.$message">{{ error.$message }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="d-block mb-3">
                        <label class="font-size-14">Password </label>
                        <input v-model="staff.password" :disabled="staffUpdate" type="password"
                          class="form-control simpleInputStyle" placeholder="Password"
                          :class="{ 'is-invalid': submitted && v$.staff.password.$error, }" />
                        <div class="invalid-feedback" v-for="(error, key) in v$.staff.password.$errors" :key="key">
                          <span v-if="error.$message">{{ error.$message }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12" v-if="staffUpdate !== true">
                      <div class="d-block text-end">
                        <input type="button" @click="submitStaff" class="btn btn-theme btn-custom-wdth" value="Save" />
                      </div>
                    </div>
                    <div class="col-12" v-else>
                      <div class="d-block text-end">
                        <input type="button" @click="updateStaff" class="btn btn-theme btn-custom-wdth" value="Update" />
                      </div>
                    </div>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>
          <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
            theme-color="#df6a0d" :rows-per-page="10" buttons-pagination show-index
            table-class-name="table-custom-style noWrapHeading">
            <template #item-status="{ status, id }">
              <span v-if="status == 1">
                <button class="btn btn-sm btn-success" @click="updateStatus(id)">Active</button>
              </span>
              <span v-else>
                <button class="btn btn-sm btn-danger" @click="updateStatus(id)">InActive</button>
              </span>
            </template>
            <template #item-fullName="{ firstName, lastName }">
              <p class="mb-0 text-nowrap fw-medium">{{ firstName }} {{ lastName }}</p>
            </template>
            <template #item-actionBtn="{ id }">
              <span>
                <button @click="editStaff(id)" class="btn btn-sm btn-theme-dark">
                  <i class="mdi mdi-eye"></i>
                  Edit
                </button>
              </span>
              <span class="m-2">
                <button @click="deleteStaff(id)" class="btn btn-sm btn-theme-dark ">
                  <i class="mdi mdi-trash"></i>
                  Delete
                </button>
              </span>
            </template>
          </EasyDataTable>
        </div>
      </div>
    </main>
  </Layout>
</template>
